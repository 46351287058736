import React from 'react'
import { render } from 'react-dom'
import GenericCarousel from './GenericCarousel'

document.addEventListener('DOMContentLoaded', () => {
  const items = App.State.shopProducts
  render(
    <GenericCarousel
      items={items}
      title={I18n.t('carrousel_title.shop_products')}
      exploreUrl="https://dyler.com/shop"
      exploreText={I18n.t('visit_shop')}
      isShop
    />,
    document.getElementById('shop-products')
  )
})
