import { useRef } from 'react'
import Slider from 'react-slick'
import Button from '../../AccountV2/components/Button'
import { CarGridDetails } from '../../components/CarCards/CarGridDetails'
import { GridCard } from '../../components/GridCard'

function MobilePrevArrow(props) {
  const { className, onClick } = props
  return (
    <button
      className={`${className} mobile-prev-arrow`}
      aria-label="premium cars carousel prev"
      type="button"
      data-role="none"
      style={{ display: 'block' }}
      onClick={onClick}
    />
  )
}

function MobileNextArrow(props) {
  const { className, onClick } = props
  return (
    <button
      className={`${className} mobile-next-arrow`}
      aria-label="premium cars carousel next"
      type="button"
      data-role="none"
      style={{ display: 'block' }}
      onClick={onClick}
    />
  )
}

function GenericCarousel({
  items,
  title,
  exploreUrl,
  exploreText,
  isShop,
  isPremium,
}) {
  const ref = useRef(null)

  const handlePrevSlide = () => {
    ref.current.slickPrev()
  }

  const handleNextSlide = () => {
    ref.current.slickNext()
  }

  const thumbSliderSettings = {
    swipeToSlide: true,
    focusOnSelect: true,
    speed: 250,
    infinite: true,
    slidesToShow: Math.min(App.State.premiumCars.length, 4),
    slidesToScroll: 4,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(App.State.premiumCars.length, 4),
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: true,
          slidesToShow: Math.min(App.State.premiumCars.length, 2),
          slidesToScroll: 1,
          lazyLoad: 'anticipated',
          prevArrow: <MobilePrevArrow />,
          nextArrow: <MobileNextArrow />,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: true,
          slidesToShow: Math.min(App.State.premiumCars.length, 2),
          slidesToScroll: 1,
          lazyLoad: 'anticipated',
          prevArrow: <MobilePrevArrow />,
          nextArrow: <MobileNextArrow />,
        },
      },
    ],
  }

  return (
    <>
      <div className="section-heading align-items-center mb-4 slider-head-wrapper">
        <h2 className="col-md-auto mb-0 pe-md-0 fw-bold text-dark">
          {title}
        </h2>
        <div>
          <Button
            type="button"
            className="carousel-button prev"
            aria-label="carousel prev"
            onClick={() => handlePrevSlide()}
          >
            <i className="fa fa-arrow-left" />
          </Button>
          <Button
            type="button"
            className="carousel-button next"
            aria-label="carousel prev"
            onClick={() => handleNextSlide()}
          >
            <i className="fa fa-arrow-right" />
          </Button>
          <a
            href={exploreUrl}
            className="btn btn-head rounded-8"
          >
            {exploreText}
          </a>
        </div>
      </div>

      <div className="homepage-slider">
        <Slider ref={ref} {...thumbSliderSettings}>
          {items.map((item) => (
            <div className="mb-4" key={item.id}>
              {isShop ? (
                <GridCard
                  image={{
                    src: item.image,
                    alt: 'Suggestion car image',
                  }}
                  url={`${item.url}`}
                  title={item.title}
                >
                  <CarGridDetails car={item} currency={item.currency} />
                </GridCard>
              ) : (
                <GridCard
                  image={{
                    src: item.image,
                    alt: 'Suggestion car image',
                  }}
                  url={`${item.path}`}
                  title={item.title}
                  description={item.details}
                  country={item.location_country}
                  withSoldMark={item.status === 'sold'}
                  isPremium={isPremium}
                >
                  <CarGridDetails car={item} currency={App.State.currentCurrency}/>
                </GridCard>
              )}
            </div>
          ))}
        </Slider>
      </div>
    </>
  )
}

export default GenericCarousel
