import styled from 'styled-components'
import { CrownIcon } from '../../Icons/CrownIcon'

const RibbonWrapper = styled.div`
  .ribbon {
    position: relative;
    z-index: 1;

    .ribbon-tip {
      background-color: #9c3228;
      border-radius: 0 0.25rem 0.25rem 0;
      float: right;
      height: 0.4rem;
      width: 0.4rem;
      margin-top: -0.4rem;
      position: absolute;
      right: 0;

      &:before {
        content: '';
        background-color: #c75146;
        height: 0.4rem;
        width: 0.4rem;
        display: block;
        position: absolute;
        z-index: -1;
        border-radius: 0 0.25rem 0 0;
      }
    }
    .ribbon-face {
      background-color: #c75146;
    }
  }
`

export const PremiumRibbon = ({ size = 'md' }) => {
  const ribbonFaceStyles = {}
  if (size === 'md') {
    ribbonFaceStyles.borderTopLeftRadius = '8px'
    ribbonFaceStyles.borderBottomLeftRadius = '8px'
    ribbonFaceStyles.borderBottomRightRadius = '8px'
  } else {
    ribbonFaceStyles.borderTopRightRadius = '0px'
  }

  return (
    <RibbonWrapper>
      <div className='position-relative'>
        <div className='ribbon position-absolute top-0 end-0'>
          <div className='ribbon-tip'></div>
          <div
            style={ribbonFaceStyles}
            className={`ribbon-face d-flex align-items-center text-light fw-bold ${
              size === 'md' ? 'ps-3 pe-4 py-2' : 'badge'
            }`}
          >
            <div
              style={{ backgroundColor: '#9C3228' }}
              className='p-1 rounded-circle d-inline-block me-2 d-inline-flex align-items-center justify-content-center fs-6'
            >
              <CrownIcon color='#F9B533' height='8' width='7' />
            </div>
            <span style={{ fontSize: size === 'md' ? '0.75rem' : '0.6rem' }}>
              Premium
            </span>
          </div>
        </div>
      </div>
    </RibbonWrapper>
  )
}
