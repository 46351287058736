import PropTypes from 'prop-types'
import Image from 'react-image'
import { PremiumRibbon } from '../AccountV2/components/WizardForm/PremiumRibbon'

export const GridCard = ({
  openInNewTab = true,
  url,
  image,
  country,
  description,
  children,
  key,
  withSoldMark = false,
  isPremium = false
}) => {
  const mobileImageStyle = App.State.isMobile
    ? { width: '160px', height: '110px', minWidth: '160px', minHeight: '110px' }
    : { width: '260px', height: '180px' }

  const renderCardHeader = () => (
    <div className='card-header-img position-relative'>
      <Image
        src={image.src}
        alt={image.alt}
        className='card-img-top'
        loader={
          <img
            src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mN8+ertfwAJRQPBTZk8XgAAAABJRU5ErkJggg=='
            alt={image.alt}
            style={mobileImageStyle}
          />
        }
      />

      <div
        className='d-flex'
        style={{ position: 'absolute', top: '10px', left: '10px' }}
      >
        {country && (
          <div className='badge bg-white text-dark'>
            <span
              className={`flag-icon flag-icon-${country?.toLocaleLowerCase()}`}
            />
            <span className='mx-1 text-dark'>{country}</span>
          </div>
        )}

        {withSoldMark && (
          <div className='badge ms-2 bg-danger text-white'>
            <i className='fa-solid fa-sack-dollar me-1' />
            {I18n.t('sold')}
          </div>
        )}
      </div>
      {isPremium && (
        <div
          style={{
            position: 'absolute',
            top: '10px',
            right: '0px',
            marginRight: '-6px'
          }}
        >
          <PremiumRibbon size={'sm'} />
        </div>
      )}

      {description && (
        <div className='card-img-overlay text-white align-items-end d-flex'>
          <small>{description}</small>
        </div>
      )}
    </div>
  )

  return (
    <a
      href={url}
      className='card flat-card'
      target={!openInNewTab ? '_self' : '_blank'}
      rel='noreferrer'
    >
      {renderCardHeader()}
      <div className='card-body justify-content-between d-flex flex-column p-1 pt-2'>
        {children}
      </div>
    </a>
  )
}

GridCard.propTypes = {
  key: PropTypes.string,
  openInNewTab: PropTypes.bool,
  url: PropTypes.string.isRequired,
  image: PropTypes.shape({ src: PropTypes.string, alt: PropTypes.string })
    .isRequired,
  country: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element).isRequired
}

GridCard.defaultProps = {
  openInNewTab: true,
  country: undefined,
  description: null,
  key: ''
}
