export const CrownIcon = ({ color, height, width }) => (
  <svg
    height={height}
    width={width}
    style={{ color: color }}
    viewBox='0 0 10 9'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M4.99974 7.86311C6.01082 7.86311 6.99546 7.97603 7.94221 8.18886C8.1908 8.24484 8.439 8.09284 8.50936 7.84794L9.87729 3.08177C9.99605 2.66777 9.55737 2.3152 9.17875 2.52044L6.97136 3.71694L5.43492 0.626955C5.2563 0.267577 4.74338 0.267577 4.56476 0.626955L3.02831 3.71694L0.820929 2.52044C0.44231 2.3152 0.00343709 2.66758 0.122387 3.08177L1.49032 7.84794C1.56068 8.09284 1.80888 8.24484 2.05747 8.18886C3.00421 7.97603 3.98866 7.86311 4.99974 7.86311Z'
      fill='currentColor'
    />
  </svg>
)
