import { PriceDisplay } from '../PriceDisplay'
import { AddToGarageModalButton } from '../AddToGarage/AddToGarageModalButton'

export const CarGridDetails = ({
  displayAddToGarageButton,
  car,
  currency,
  openGarageModal,
}) => (
  <>
    <span className="text-truncate text-black" title={car.title}>
      {car.title}
    </span>
    <div className="text-primary font-heading text-black">
      <PriceDisplay
        price={car.price}
        currency={currency}
        auctioned={car.auctioned}
        auctionActive={car.auction_active}
        auctionStartAt={car.auction_start_at}
        auctionEndAt={car.auction_end_at}
      />
    </div>

    {displayAddToGarageButton && (
      <div className="d-flex justify-content-end">
        {car.published && (
          <AddToGarageModalButton
            classes="text-neutral p-0"
            onButtonClick={openGarageModal}
            carId={car.id}
          />
        )}
      </div>
    )}
  </>
)
