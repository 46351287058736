import styled from 'styled-components'
import PropTypes from 'prop-types'

const StyledButton = styled.button`
  color: '#343434';
  border-radius: 8px;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
`
const StyledAnchor = styled.a`
  color: '#343434';
  border-radius: 8px;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
`

/**
 * Button component can be used in every place where button is needed.
 * It can be of different sizes and different colors.
 * @component
 * @category Account
 */
const Button = ({
  children,
  fullWidth = false,
  size = 'md',
  color = 'primary',
  onClick,
  className,
  style,
  id,
  href,
  disabled,
  type,
}) => {
  const props = {
    className: `btn btn-${size} btn-${color} ${
      color === 'tertiary' && 'btn-outline-secondary text-dark'
    } ${className}`,
    href,
    onClick,
    fullWidth,
    style,
    id,
    size,
    color,
    disabled,
    type,
  }

  if (href) {
    return <StyledAnchor {...props}>{children}</StyledAnchor>
  }

  return <StyledButton {...props}>{children}</StyledButton>
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
  fullWidth: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  color: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
  id: PropTypes.string,
  href: PropTypes.string,
}

Button.defaultProps = {
  fullWidth: false,
  size: 'md',
  color: 'primary',
  onClick: undefined,
  className: undefined,
  style: undefined,
  id: undefined,
  href: undefined,
}

export default Button
