import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import format from 'date-fns/format'
import differenceInSeconds from 'date-fns/differenceInSeconds'

export const timeDiff = (date) => formatDistanceToNow(date)

export const strictTimeDiff = (date) => formatDistanceToNowStrict(date)

export const dateWithoutTime = (date) => format(date, 'P')

export const timeDiffToNowInSeconds = (date) =>
  differenceInSeconds(date, new Date())
