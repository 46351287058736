const styles = {
  onTop: {
    zIndex: 100,
    position: 'relative',
  },
}

export const AddToGarageModalButton = ({ classes, onButtonClick, carId }) => (
  <button
    type="button"
    className={`btn ${classes}`}
    style={styles.onTop}
    onClick={(e) => onButtonClick(e, carId)}
    title={I18n.t('garage.add_to_garage')}
  >
    <i className="far fa-star fa-lg mx-1 add-to-garage-icon" />
  </button>
)
