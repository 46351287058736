import { lazy, Suspense } from 'react'
import PropTypes from 'prop-types'
import { dateWithoutTime } from '../lib/time'

const AuctionTime = lazy(() =>
  import('../CarsSearch/components/SearchResult/AuctionTime')
)

export const PriceDisplay = ({
  price,
  currency,
  auctioned,
  auctionActive,
  auctionEndAt,
  auctionStartAt,
}) => (
  <>
    {!price && !auctionActive && !auctioned && (
      <span title="Price on Request">{I18n.t('price_on_request')}</span>
    )}

    {price > 0 && !auctionActive && !auctioned && (
      <span>
        {String(price).replace(/(\d)(?=(\d{3})+$)/g, '$1 ')} {currency}
      </span>
    )}

    {auctionActive && (
      <Suspense fallback={<div>{I18n.t('loading')}</div>}>
        <AuctionTime auctionEndAt={auctionEndAt} />
      </Suspense>
    )}

    {auctioned && (
      <span>Starts {dateWithoutTime(new Date(auctionStartAt))}</span>
    )}
  </>
)

PriceDisplay.propTypes = {
  price: PropTypes.number,
  currency: PropTypes.string,
  auctioned: PropTypes.bool,
  auctionActive: PropTypes.bool,
  auctionEndAt: PropTypes.string,
  auctionStartAt: PropTypes.string,
}
